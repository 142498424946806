import { connect } from 'react-redux';
import Component from './component';

import { migrate } from 'Redux/modules/admin/userOffers/actions';
import { list as listUsers } from 'Redux/modules/admin/users/actions';
import { list as listOrders } from 'Redux/modules/admin/orders/actions';

export default connect(
    state => ({
        orders: state.adminOrders.list,
    }),
    dispatch => ({
        actions: {
            migrate: dispatch(migrate),
            listUsers: dispatch(listUsers),
            listOrders: dispatch(listOrders),
        },
    }),
)(Component);