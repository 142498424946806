import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    .page-migration {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.5em;
        border: 1px solid #CCCCCC;
        margin: 2em auto;

        .page-migration-label {
            font-size: 1.5em;
            color: #666666;
            font-weight: 300;
        }

        .component-button {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }

    .admin-element-editor {
        position: relative;
        z-index: 2;

        .forms-wrapper {
            width: 100% !important;
        }
        .data-wrapper {
            display: none;
        }

        .form-elements {
            display: flex;
            justify-content: space-between;
            align-items: center;

            > * {
                width: 45%;
            }
        }
    }
    .admin-paginated-list {
        position: relative;
        z-index: 1;
    }
`;
