import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { API_RESOURCE_USER_OFFER, API_RESOURCE_USERS } from 'Consts/apiResources';
import { ADMIN_USER_OFFERS_MANAGE, OLD_PANEL_ORDER_MANAGE } from 'Consts/routes';
import { USER_ROLE_ADEPT, USER_ROLE_TRAINER } from 'Consts/userRoles';

import Logger from 'Services/Logger';

import { parseQueryToObject } from 'Utils/querystring';
import { withVariables } from 'Utils/string';
import { getFullName as getUserFullName } from 'Utils/user';
import { filterKeys, fromSelectObject } from 'Utils/object';
import { getFormattedDate } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layoutAdmin/panel/PaginatedList';
import PaginatedListElement from 'Components/layoutAdmin/panel/PaginatedListElement';
import Button from 'Components/layoutAdmin/Button';

export default class PanelOffersMigrator extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            migrate: PropTypes.func.isRequired,
            listUsers: PropTypes.func.isRequired,
            listOrders: PropTypes.func.isRequired,
        }).isRequired,
        orders: PropTypes.shape({
            elements: PropTypes.array.isRequired,
        }).isRequired,
        predefinedQuery: PropTypes.object,
    };
    static defaultProps = {
        predefinedQuery: {},
    };

    state = {
        pendings: [],
        migratedList: [],
    };
    defaultQuery = {
        page: 1,
        orderBy: '',
        timestamp: undefined,
        userId: undefined,
        trainerId: undefined,
        migrated: undefined,
        type: undefined,
        category: undefined,
        ...this.props.predefinedQuery,  //eslint-disable-line react/destructuring-assignment
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();
        
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.listOrders({ 
                ...queryObject,
                userId: fromSelectObject(queryObject.userId) || undefined,
                trainerId: fromSelectObject(queryObject.trainerId) || undefined,
                migrated: fromSelectObject(queryObject.migrated) || 'no',
                type: fromSelectObject(queryObject.type) || undefined,
            });
        }, 500);
    }

    onMigrate = (order, redirect = true) => {
        const { actions, history } = this.props;

        this.setState(prevState => ({
            ...prevState,
            pendings: [
                ...prevState.pendings,
                order.id,
            ],
        }));
        return actions.migrate({
            orderId: order.id,
        })
            .then(response => {
                const userOffer = response.payload[API_RESOURCE_USER_OFFER];

                this.setState(prevState => ({
                    ...prevState,
                    pendings: prevState.pendings.filter(orderId => orderId !== order.id),
                }));

                if(redirect) {
                    history.push(
                        withVariables(
                            ADMIN_USER_OFFERS_MANAGE.path,
                            { id: userOffer.id }
                        )
                    );
                }

                return userOffer;
            })
            .catch(error => {
                Logger.error('[MIGRTOR] Error', error);
                toast('Wystąpił błąd, spróbuj ponownie');
                
                this.setState(prevState => ({
                    ...prevState,
                    pendings: prevState.pendings.filter(orderId => orderId !== order.id),
                }));

                return null;
            });
    }

    onMigratePage = () => {
        const { orders } = this.props;

        orders.elements.forEach(order => {
            this.onMigrate(order, false)
                .then(userOffer => {
                    this.setState(prevState => ({
                        ...prevState,
                        migratedList: [
                            ...prevState.migratedList,
                            { userOffer, order, status: Boolean(userOffer) },
                        ],
                    }));
                });
        });
    }

    render() {
        const { orders, location, history, actions } = this.props;
        const { pendings, migratedList } = this.state;

        return (
            <StyledComponent
                className="panel-offers-migrator"
                styles={require('./styles')}
            >
                <div className="page-migration">
                    <p className="page-migration-label">
                        Migracja masowa
                    </p>
                    <Button
                        onClick={() => this.onMigratePage()}
                    >
                        Migruj całą stronę
                    </Button>
                </div>
               
                <PaginatedList
                    location={location}
                    history={history}
                    collection={orders}
                    title="Lista zamówień"
                    queryConfig={this.getQueryConfig()}
                    onMapElement={element => {
                        const migratedElement = migratedList.find(migratedElement => migratedElement.order.id === element.id);

                        return (
                            <PaginatedListElement
                                key={element.id}
                                title={element.customer
                                    ? `${element.customer.name} ${element.customer.surname} (${element.customer.email})`
                                    : 'Brak użytkownika'
                                }
                                subtitle={element.trainer
                                    ? `Trener: ${element.trainer.name} ${element.trainer.surname} (${element.trainer.email})`
                                    : 'Brak trenera'
                                }
                                additionals={[{
                                    name: 'Status',
                                    value: element.status,
                                }, {
                                    name: 'Typ',
                                    value: element.type === 1 ? 'Pojedyczne' : 'Cykliczne',
                                }, {
                                    name: 'Ostatnia udana płatność',
                                    value: element.lastSuccessPayment
                                        ? getFormattedDate(element.lastSuccessPayment.createdAt)
                                        : 'Brak',
                                }, {
                                    name: 'Ostatnia płatność',
                                    value: element.lastPayment
                                        ? getFormattedDate(element.lastPayment.createdAt)
                                        : 'Brak',
                                }]}
                                controls={[{
                                    type: 'button',
                                    label: 'Otwórz w starym panelu',
                                    visible: true,
                                    href: withVariables(OLD_PANEL_ORDER_MANAGE, { id: element.id }),
                                    target: '_blank',
                                }, {
                                    type: 'button',
                                    label: 'Szczegóły',
                                    visible: migratedElement && migratedElement.status,
                                    href: migratedElement && migratedElement.userOffer && withVariables(ADMIN_USER_OFFERS_MANAGE.path, { id: migratedElement.userOffer.id }),
                                    target: '_blank',
                                }, {
                                    type: 'button',
                                    label: migratedElement
                                        ? migratedElement.status    
                                            ? 'Zmigrowane'
                                            : 'Błąd migracji'
                                        : 'Migruj',
                                    visible: true,
                                    onClick: () => this.onMigrate(element),
                                    disabled: Boolean(migratedElement && migratedElement.status),
                                    loading: Boolean(pendings.find(pending => pending === element.id)),
                                } ]}
                            />
                        );
                    }}
                    filters={[{
                        type: 'select',
                        name: 'userId',
                        label: 'Adept',
                        inputProps: {
                            dynamic: true,
                            onLoad: query => actions.listUsers({ search: query, role: USER_ROLE_ADEPT }),
                            onMapResponse: response => response.payload[API_RESOURCE_USERS].elements,
                            onMapOption: element => ({
                                value: element.id,
                                label: getUserFullName(element).label,
                            }),
                        },
                    }, {
                        type: 'select',
                        name: 'trainerId',
                        label: 'Trener',
                        inputProps: {
                            dynamic: true,
                            onLoad: query => actions.listUsers({ search: query, role: USER_ROLE_TRAINER }),
                            onMapResponse: response => response.payload[API_RESOURCE_USERS].elements,
                            onMapOption: element => ({
                                value: element.id,
                                label: getUserFullName(element).label,
                            }),
                        },
                    }, {
                        name: 'migrated',
                        label: 'Zmigrowane',
                        type: 'select',
                        inputProps: {
                            options: [{
                                label: 'Nie',
                                value: '',
                            }, {
                                label: 'Tak',
                                value: 'yes',
                            }],
                        },
                    }, {
                        name: 'type',
                        label: 'Typ',
                        type: 'select',
                        inputProps: {
                            options: [{
                                label: 'Wszystkie',
                                value: '',
                            }, {
                                label: 'Pojedyncze',
                                value: '1',
                            }, {
                                label: 'Cykliczne',
                                value: '2',
                            }],
                        },
                    }, {
                        name: 'category',
                        label: 'Kategoria',
                        type: 'select',
                        inputProps: {
                            options: [{
                                label: 'Wszystkie',
                                value: '',
                            }, {
                                label: 'Diagnostyczne',
                                value: 'diagnostic',
                            }, {
                                label: 'Abonamentowe',
                                value: 'abo',
                            }],
                        },
                    }]}
                />
            </StyledComponent>
        );
    }
}